import { useState, Children, cloneElement, useMemo } from 'react';
import { Box, Drawer, Group, Button, ActionIcon, Divider, Stack, CloseIcon } from '@mantine/core';
import { IconFilter, IconFilterFilled, IconX } from '@tabler/icons-react';

import SearchInput from './inputs/SearchInput';

const MobileFiltersDrawer = ({
  filters = {},
  initialFilters = {},
  onApply = () => {},
  onClear = () => {},
  searchProps = {},
  children,
}) => {
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [filtersToApply, setFiltersToApply] = useState(filters);

  const hasActiveFilters = useMemo(
    () => Object.keys(filters).some((key) => filters[key] !== initialFilters[key]),
    [filters, initialFilters],
  );

  const hasFiltersToApply = useMemo(
    () => Object.keys(filtersToApply).some((key) => filtersToApply?.[key] !== filters[key]),
    [filtersToApply, filters],
  );

  const handleApplyFilters = () => {
    onApply(filtersToApply);
    setDrawerOpened(false);
  };

  const handleFilterChange = (key, value, toggle = true) => {
    setFiltersToApply((current) => ({
      ...current,
      [key]: toggle && current[key] === value ? null : value,
    }));
  };

  const handleClose = () => {
    setDrawerOpened(false);
    setFiltersToApply(filters);
  };

  const handleClear = () => {
    onClear();
    setFiltersToApply(initialFilters);
  };

  const Icon = hasActiveFilters ? IconFilterFilled : IconFilter;

  return (
    <Box mb="md">
      <Group gap={4}>
        <SearchInput
          label=""
          placeholder="Search practices"
          initialValue={filters?.name}
          onSearch={(value) => onApply({ ...filters, name: value })}
          flex={1}
          {...searchProps}
        />
        <ActionIcon c="snh" size="input-md" variant="default" onClick={() => setDrawerOpened(true)}>
          <Icon size={24} />
        </ActionIcon>
        {hasActiveFilters && (
          <ActionIcon c="red" size="input-md" variant="default" onClick={handleClear}>
            <IconX size={24} />
          </ActionIcon>
        )}
      </Group>
      <Drawer
        radius="md"
        hiddenFrom="md"
        position="bottom"
        opened={drawerOpened}
        onClose={handleClose}
        title="Filters"
        offset={8}
        styles={{
          title: { fontSize: '1.25rem', fontWeight: 500 },
          content: { height: 'auto' },
        }}
      >
        <Divider mb="md" />
        <Stack gap="md" px="xs" mb="md">
          {Children.map(children, (child) => cloneElement(child, { filtersToApply, handleFilterChange }))}
        </Stack>
        <Divider mb="md" />
        <Group grow>
          <Button
            color="red"
            fullWidth
            onClick={handleClear}
            leftSection={<CloseIcon size={16} />}
            disabled={!hasFiltersToApply && !hasActiveFilters}
          >
            Clear
          </Button>
          <Button variant="outline" fullWidth onClick={handleApplyFilters} disabled={!hasFiltersToApply}>
            Apply
          </Button>
        </Group>
      </Drawer>
    </Box>
  );
};

export default MobileFiltersDrawer;
