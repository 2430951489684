export const fetchApi = async (session, url, options = {}) => {
  const headers = {
    ...options?.headers,
    'Content-Type': 'application/json',
    Authorization: session?.access_token,
  };

  const apiEndpoint = '/api';

  const response = await fetch(`${apiEndpoint}${url}`, { ...options, headers });
  const responseBody = await response.json();

  if (!response.ok) {
    const error = new Error('Failed to fetch');
    const { code } = responseBody || {};
    error.code = code;
    throw error;
  }

  return responseBody;
};
