import { Link } from 'react-router-dom';
import { Button, Card, Text } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';

import supabase from '../../config/supabaseClient';
import CustomCard from '../common/CustomCard';
import { DrillVideo } from '../drills/DrillShow';

const DrillOfTheWeek = () => {
  const {
    data: drillOfTheWeek,
    isPending,
    isError,
  } = useQuery({
    queryKey: ['drill'],
    queryFn: async () =>
      await supabase
        .from('drill')
        .select('*')
        .eq('is_drill_of_the_week', true)
        .single()
        .throwOnError()
        .then(({ data }) => data),
  });

  const url = drillOfTheWeek?.video;

  return (
    <CustomCard title="Drill of the Week" isLoading={isPending} isError={isError}>
      <Card.Section p="xs" pt={0} mb="md">
        <DrillVideo url={url} data-hideshadow />
      </Card.Section>
      <Text size="lg" fw={500}>
        {drillOfTheWeek?.name}
      </Text>
      <Text size="sm" c="dimmed" lineClamp={3}>
        {drillOfTheWeek?.description}
      </Text>
      <Button component={Link} to={`drills/${drillOfTheWeek?.id}`} mt="md">
        View
      </Button>
    </CustomCard>
  );
};

export default DrillOfTheWeek;
