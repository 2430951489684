import { Container, Grid } from '@mantine/core';

import PageHeader from '../components/common/PageHeader';
import DrillOfTheWeek from '../components/home/DrillOfTheWeek';
import FavoritePractices from '../components/home/FavoritePractices';

import QuickLinks from '../components/home/QuickLinks';

export default function Home() {
  return (
    <Container size="xl" p={0}>
      <PageHeader title="Home" />
      <Grid gutter={{ base: 'xl', md: 'lg' }} overflow="hidden" mb="lg">
        <Grid.Col span={{ base: 12, md: 6 }} order={{ base: 1, md: 2 }}>
          <DrillOfTheWeek />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }} order={{ base: 2, md: 1 }}>
          <QuickLinks />
        </Grid.Col>
        <Grid.Col span={12} order={3}>
          <FavoritePractices />
        </Grid.Col>
      </Grid>
    </Container>
  );
}
