import { useState } from 'react';
import { Chip, Group, Loader, Input, Select } from '@mantine/core';
import { useUncontrolled } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';
import { IconClockHour3 } from '@tabler/icons-react';

import supabase from '../../../config/supabaseClient';

const useDurations = (enabled = true) =>
  useQuery({
    queryKey: ['durations'],
    queryFn: async () => await supabase.from('duration').select().throwOnError(),
    select: ({ data }) => data.map(({ id, name }) => ({ value: String(id), label: name })),
    enabled,
  });

const DurationsChipGroup = ({ data, ...props }) => {
  return (
    <Input.Wrapper label="Duration" size="md">
      <Group gap="xs">
        <Chip.Group {...props}>
          {(data || []).map(({ value, label }) => (
            <Chip key={value} value={value} fw={500}>
              {label}
            </Chip>
          ))}
        </Chip.Group>
      </Group>
    </Input.Wrapper>
  );
};

const DurationSelect = ({ value, defaultValue, onChange, defaultEnabled = true, chips = false, ...props }) => {
  const [enabled, setEnabled] = useState(defaultEnabled);
  const { data, isLoading } = useDurations(enabled);
  const [_value, setValue] = useUncontrolled({ value, defaultValue, onChange });

  const handleChange = (value) => {
    setValue(value ? parseInt(value) : null);
  };

  if (chips) return <DurationsChipGroup value={_value && String(_value)} onChange={handleChange} data={data} />;

  return (
    <Select
      value={_value && String(_value)}
      onChange={handleChange}
      data={data}
      label="Duration"
      placeholder="Select a duration"
      leftSection={<IconClockHour3 size={18} />}
      rightSection={isLoading && <Loader size={18} />}
      searchable
      clearable
      onDropdownOpen={() => {
        if (!enabled) {
          setEnabled(true);
        }
      }}
      {...props}
    />
  );
};

export default DurationSelect;
