import { Badge, createTheme } from '@mantine/core';

const theme = createTheme({
  fontFamily: 'Poppins, sans-serif',
  headings: {
    fontFamily: 'Poppins, sans-serif',
  },
  primaryColor: 'snh',
  colors: {
    snh: ['#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000'],
    red: ['#D19999', '#C58080', '#B96666', '#AE4D4D', '#A23333', '#971A1A', '#8B0000', '#7D0000', '#6F0000', '#610000'],
  },
  defaultRadius: 'md',

  components: {
    Badge: Badge.extend({
      defaultProps: {
        tt: 'none',
      },
    }),
  },
});

export default theme;
