import { useNavigate } from 'react-router-dom';
import { Card, Text, Group, SimpleGrid, ThemeIcon } from '@mantine/core';
import { IconPlus, IconArrowRight } from '@tabler/icons-react';

import { AnnouncementIcon, DrillIcon, PracticeIcon } from '../../icons';

import classes from './QuickLinks.module.css';

export default function QuickLinks() {
  const navigate = useNavigate();

  const features = [
    {
      icon: DrillIcon,
      title: 'View Drills',
      description: 'Browse our collection of professional training drills',
      link: '/drills',
      linkText: 'Explore drills',
    },
    {
      icon: PracticeIcon,
      title: 'My Practices',
      description: 'Access and manage your saved practice routines',
      link: '/practices',
      linkText: 'View practices',
    },
    {
      icon: IconPlus,
      title: 'Create Practice',
      description: 'Design a new custom practice routine',
      link: '/practices/create',
      linkText: 'Start creating',
    },
    {
      icon: AnnouncementIcon,
      title: 'Announcements',
      description: 'Stay informed with latest announcements',
      link: '/announcements',
      linkText: 'Read announcements',
    },
  ];

  return (
    <SimpleGrid type="container" cols={{ base: 1 }} spacing="xs">
      {features.map((feature, index) => {
        const { icon: Icon, title, description, link, linkText } = feature;
        return (
          <Card className={classes.Card} key={index} p="md" withBorder shadow="sm" onClick={() => navigate(link)}>
            <Group gap="xs" mb="xs">
              <Icon size={20} />
              <Text fw={500} size="lg">
                {title}
              </Text>
            </Group>
            <Text size="sm" c="dimmed" mb="md">
              {description}
            </Text>
            <Group gap={4}>
              <Text size="sm" c="blue">
                {linkText}
              </Text>
              <ThemeIcon c="blue" variant="transparent" size="xs">
                <IconArrowRight size={14} />
              </ThemeIcon>
            </Group>
          </Card>
        );
      })}
    </SimpleGrid>
  );
}
