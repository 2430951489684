import { useEffect } from 'react';
import { useToggle, upperFirst } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import { TextInput, PasswordInput, Text, Paper, Group, Button, Divider, Checkbox, Anchor, Stack } from '@mantine/core';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { signIn, signUp } from '../../utils/auth';

import { GoogleButton } from './GoogleButton';
import { isEmail } from '../practices/SharePractice';

export function AuthenticationForm({ register = false, ...props }) {
  const [type, toggle] = useToggle(['login', 'register']);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const emailUrlParam = searchParams.get('e');

  useEffect(() => {
    if (location?.state?.type === 'register' || register) {
      toggle('register');
    } else {
      toggle('login');
    }
  }, [location, register, toggle]);

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      email: emailUrlParam || '',
      name: '',
      password: '',
      terms: true,
    },

    validate: {
      email: (val) => isEmail(val),
      password: (val) => (val.length <= 6 ? 'Password should include at least 6 characters' : null),
    },
  });

  useEffect(() => {
    if (emailUrlParam && !form.values.email) {
      form.setFieldValue('email', emailUrlParam);
    }
  }, [emailUrlParam, form]);

  const handleToggle = () => {
    form.setValues({ name: '', password: '', terms: true });
    toggle();
  };

  const handleSubmit = async (values) => {
    const { email, password, name } = values || {};

    const { error } = type === 'register' ? await signUp(email, password, name) : await signIn(email, password);

    if (error) {
      const formError =
        type === 'register'
          ? { email: 'User with this email already exists' }
          : { email: 'Invalid email or password', password: 'Invalid email or password' };
      form.setErrors(formError);
      return;
    }

    if (type === 'register') {
      navigate(`/login${emailUrlParam ? `?e=${emailUrlParam}` : ''}`);
    }
  };

  return (
    <Paper p="xl" withBorder {...props}>
      <Text size="lg" fw={500}>
        Welcome to SNH Performance, {type} with
      </Text>

      <Group grow mb="md" mt="md">
        <GoogleButton radius="xl">Google</GoogleButton>
      </Group>

      <Divider label="Or continue with email" labelPosition="center" my="lg" />

      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          {type === 'register' && (
            <TextInput label="Name" placeholder="Your name" key={form.key('name')} {...form.getInputProps('name')} />
          )}

          <TextInput
            required
            label="Email"
            placeholder="example@domain.com"
            key={form.key('email')}
            {...form.getInputProps('email')}
          />

          <PasswordInput
            required
            label="Password"
            placeholder="Your password"
            key={form.key('password')}
            {...form.getInputProps('password')}
          />

          {type === 'register' && (
            <Checkbox
              label="I accept terms and conditions"
              key={form.key('terms')}
              {...form.getInputProps('terms', { type: 'checkbox' })}
            />
          )}
        </Stack>

        <Group justify="space-between" mt="xl">
          <Anchor component="button" type="button" c="dimmed" onClick={handleToggle} size="xs">
            {type === 'register' ? 'Already have an account? Login' : "Don't have an account? Register"}
          </Anchor>
          <Button type="submit" radius="xl">
            {upperFirst(type)}
          </Button>
        </Group>
      </form>
    </Paper>
  );
}
