// Import styles of packages that you've installed.
// All packages except `@mantine/hooks` require styles imports
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/carousel/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/tiptap/styles.css';
import 'mantine-datatable/styles.css';

import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import {
  Discussions,
  Drills,
  Home,
  Layout,
  Practices,
  Login,
  MyProfile,
  AdminAnnouncements,
  AdminCategories,
  AdminDrills,
  AdminDurations,
  AdminExperiences,
  AdminTemplates,
  NothingFound,
  ResetPassword,
  SubcriptionPlans,
  Announcements,
} from './pages';

import { SessionProvider } from './context/SessionContext';
import { ADMIN, SYSTEM_ADMIN } from './constants';
import theme from './theme';

import PracticeShow from './components/practices/PracticeShow';
import PracticeCreate from './components/practices/PracticeCreate';
import PracticeEdit from './components/practices/PracticeEdit';
import DrillShow from './components/drills/DrillShow';
import AdminRoute from './components/auth/AdminRoute';
import SubscribedRoutes from './components/auth/SubscribedRoutes';
import LoggedInRoutes from './components/auth/LoggedInRoutes';
import AllRoutes from './components/auth/AllRoutes';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60, // 1 minute
    },
  },
});

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        element: <AllRoutes />,
        children: [
          { path: 'login', element: <Login /> },
          { path: 'register', element: <Login register /> },
          { path: '*', element: <NothingFound /> },
          {
            element: <LoggedInRoutes />,
            children: [
              { path: 'my-profile', element: <MyProfile /> },
              { path: 'subscription-plans', element: <SubcriptionPlans /> },
              {
                element: <SubscribedRoutes />,
                children: [
                  { path: '/', element: <Home /> },
                  { path: 'practices', element: <Practices /> },
                  { path: 'practices/create', element: <PracticeCreate /> },
                  { path: 'practices/:id/edit', element: <PracticeEdit /> },
                  { path: 'practices/:id', element: <PracticeShow /> },
                  { path: 'drills', element: <Drills /> },
                  { path: 'drills/:id', element: <DrillShow /> },
                  { path: 'announcements', element: <Announcements /> },
                  { path: 'discussions', element: <Discussions /> },
                  { path: 'reset-password', element: <ResetPassword /> },
                  { path: 'admin-announcements', element: <AdminRoute element={AdminAnnouncements} type={ADMIN} /> },
                  { path: 'admin-drills', element: <AdminRoute element={AdminDrills} type={ADMIN} /> },
                  { path: 'admin-templates', element: <AdminRoute element={AdminTemplates} type={ADMIN} /> },
                  { path: 'admin-durations', element: <AdminRoute element={AdminDurations} type={SYSTEM_ADMIN} /> },
                  { path: 'admin-experiences', element: <AdminRoute element={AdminExperiences} type={SYSTEM_ADMIN} /> },
                  { path: 'admin-categories', element: <AdminRoute element={AdminCategories} type={SYSTEM_ADMIN} /> },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);

export default function App() {
  return (
    <MantineProvider theme={theme}>
      <Notifications />
      <QueryClientProvider client={queryClient}>
        <SessionProvider>
          <RouterProvider router={router} />
        </SessionProvider>
      </QueryClientProvider>
    </MantineProvider>
  );
}
