import { forwardRef, useImperativeHandle, useState } from 'react';
import { CloseButton, TextInput } from '@mantine/core';
import { useDebouncedCallback } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons-react';

const SearchInput = forwardRef(
  ({ label = 'Search', placeholder = 'Start typing...', initialValue = '', onSearch, debounce = 300, ...props }, ref) => {
    const [search, setSearch] = useState(initialValue || '');

    const handleSearch = useDebouncedCallback((value) => onSearch(value), debounce);

    const handleChange = (event) => {
      const value = event.currentTarget.value;
      setSearch(value);
      handleSearch(value);
    };

    const handleClear = () => {
      setSearch('');
      onSearch('');
    };

    // Expose input reset to parent component
    useImperativeHandle(ref, () => ({
      reset: () => {
        setSearch('');
        onSearch('');
      },
    }));

    return (
      <TextInput
        label={label}
        placeholder={placeholder}
        value={search}
        onChange={handleChange}
        leftSection={<IconSearch size={18} />}
        rightSection={search && <CloseButton variant="transparent" onClick={handleClear} />}
        size="md"
        {...props}
      />
    );
  },
);

SearchInput.displayName = 'SearchInput';

export default SearchInput;
