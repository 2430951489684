import { Select } from '@mantine/core';
import { useUncontrolled } from '@mantine/hooks';
import { IconUsers } from '@tabler/icons-react';

const SHOOTERS = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3+' },
];

const ShootersSelect = ({
  value,
  defaultValue,
  onChange,
  placeholder = 'Select # of shooters',
  allowDeselect = false,
  ...props
}) => {
  const [_value, setValue] = useUncontrolled({ value, defaultValue, onChange });

  const data = SHOOTERS;

  const handleChange = (value) => {
    setValue(value ? parseInt(value) : null);
  };

  return (
    <Select
      value={_value && String(_value)}
      onChange={handleChange}
      data={data}
      label="Shooters"
      leftSection={<IconUsers size={18} />}
      placeholder={placeholder}
      clearable={allowDeselect}
      allowDeselect={allowDeselect}
      {...props}
    />
  );
};

export default ShootersSelect;
