import { useEffect, useRef, useState } from 'react';
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import {
  Badge,
  Box,
  Button,
  Center,
  CloseButton,
  Divider,
  Grid,
  Group,
  Loader,
  Modal,
  Paper,
  ScrollArea,
  SimpleGrid,
  Stack,
  Text,
} from '@mantine/core';
import { useDisclosure, useInViewport } from '@mantine/hooks';
import { IconArrowLeft, IconList, IconPlus, IconUsers } from '@tabler/icons-react';

import { formatCategory } from '../../../utils/util';
import { LEVEL_COLOR } from '../../../constants';

import { usePracticeFormContext } from '../PracticeFormContext';
import useMediaQuery from '../../../hooks/useMediaQuery';
import supabase from '../../../config/supabaseClient';

import LevelSelect from '../../common/inputs/LevelSelect';
import CategorySelect from '../../common/inputs/CategorySelect';
import SearchInput from '../../common/inputs/SearchInput';
import ShootersSelect from '../../common/inputs/ShootersSelect';

import { DrillDetails } from '../../drills/DrillShow';

import classes from './DrillSelectModal.module.css';

const PAGE_SIZE = 20;

const useDrills = ({ categoryId, search, shooters, level }) =>
  useInfiniteQuery({
    queryKey: ['drill', { category_id: categoryId, search, shooters, level }],
    queryFn: async ({ pageParam = 0 }) => {
      let query = supabase
        .from('drill')
        .select('*, category (name, group)')
        .range(pageParam * PAGE_SIZE, (pageParam + 1) * PAGE_SIZE - 1);

      if (categoryId && categoryId !== 'custom') query.eq('category_id', categoryId);
      if (search) query.ilike('name', `%${search}%`);
      if (shooters) query.lte('shooters', shooters);
      if (level) {
        query.lte('level', level);
        query.order('level', { ascending: false });
      }

      return query.throwOnError().then(({ data }) => data);
    },
    getNextPageParam: (lastPage, allPages) => (lastPage.length === PAGE_SIZE ? allPages.length : undefined),
    placeholderData: keepPreviousData,
  });

const DrillCard = ({ drill, selectedDrills, handleView, handleClick }) => {
  const selectedIndex = selectedDrills.findIndex((d) => d.id === drill.id);
  const selected = selectedIndex !== -1;

  return (
    <Paper bg="white" p="sm" withBorder>
      <Stack h="100%" gap="xs" mb="md">
        <Group justify="space-between" wrap="nowrap">
          <Text size="lg" fw={500}>
            {drill?.name}
          </Text>
          {selected && (
            <Badge size="md" variant="default" style={{ flexShrink: 0, alignSelf: 'baseline' }}>
              # {selectedIndex + 1}
            </Badge>
          )}
        </Group>
        <Group gap="xs">
          <Badge size="md" variant="default" leftSection={<Badge size={8} circle color={LEVEL_COLOR[drill.level]} />}>
            Level {drill.level}
          </Badge>
          <Badge size="md" variant="default" leftSection={<IconUsers size={12} />}>
            {`${drill.shooters} ${drill.shooters > 1 ? 'shooters' : 'shooter'}`}
          </Badge>
        </Group>
        <Divider />
        <Group gap={4}>
          <IconList size={16} />
          <Text size="sm" fw={500}>
            {formatCategory(drill.category)}
          </Text>
        </Group>
        <Text size="sm" c="dimmed" lineClamp={2}>
          {drill?.description}
        </Text>
        <SimpleGrid cols={2} spacing="xs" mt="auto">
          <Button size="xs" variant="outline" onClick={() => handleView(drill)}>
            View
          </Button>
          <Button size="xs" variant="light" color={selected ? 'red' : 'sng.6'} onClick={() => handleClick(drill)}>
            {selected ? 'Remove' : 'Add'}
          </Button>
        </SimpleGrid>
      </Stack>
    </Paper>
  );
};

const DrillList = ({ categoryId, search, level, shooters, selectedDrills, handleClick, handleView }) => {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isPending } = useDrills({ categoryId, search, shooters, level });
  const { ref, inViewport } = useInViewport({ threshold: 1 });

  const drills = data?.pages.flat() || [];

  useEffect(() => {
    if (inViewport && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inViewport, isFetchingNextPage]);

  if (isPending) {
    return (
      <Center h={150}>
        <Loader />
      </Center>
    );
  }

  if (!drills?.length && !isFetchingNextPage)
    return (
      <Center h={300}>
        <Text size="lg" fw={500}>
          No drills found
        </Text>
      </Center>
    );
  return (
    <>
      <SimpleGrid type="container" cols={{ base: 1, '600px': 2, '1000px': 3 }} my="sm">
        {drills.map((drill) => (
          <DrillCard
            key={drill?.id}
            selectedDrills={selectedDrills}
            drill={drill}
            handleClick={handleClick}
            handleView={handleView}
          />
        ))}
      </SimpleGrid>
      {hasNextPage && (
        <Box ref={ref} h={1}>
          {isFetchingNextPage && (
            <Center h={150}>
              <Loader />
            </Center>
          )}
        </Box>
      )}
    </>
  );
};

const FilterToolbar = ({ template, filters, handleFilterChange }) => {
  const { categoryId, level, shooters } = filters;

  return (
    <Grid
      mt="sm"
      gutter="xs"
      type="container"
      breakpoints={{ xs: '576px', sm: '768px', md: '992px', lg: '1200px', xl: '1400px' }}
    >
      <Grid.Col span={{ base: 12, md: 4 }}>
        <SearchInput placeholder="Search drills" onSearch={(value) => handleFilterChange('search', value)} />
      </Grid.Col>
      <Grid.Col span={{ base: 12, xs: 6, md: 4 }}>
        <CategorySelect
          value={categoryId}
          onChange={(value) => handleFilterChange('categoryId', value)}
          disabled={template !== null}
          size="md"
        />
      </Grid.Col>
      <Grid.Col span={{ base: 6, xs: 3, md: 2 }}>
        <LevelSelect value={level} onChange={(value) => handleFilterChange('level', value)} miw={100} size="md" />
      </Grid.Col>
      <Grid.Col span={{ base: 6, xs: 3, md: 2 }}>
        <ShootersSelect value={shooters} onChange={(value) => handleFilterChange('shooters', value)} size="md" />
      </Grid.Col>
    </Grid>
  );
};

const ModalHeader = ({ showBack = false, handleBack }) => {
  if (showBack)
    return (
      <Button size="md" variant="subtle" leftSection={<IconArrowLeft size={18} />} onClick={handleBack} my="xs" mr="auto">
        Back
      </Button>
    );

  return (
    <>
      <Group p="md" justify="space-between">
        <Text size="xl" fw={500}>
          Drill Select
        </Text>
        <Modal.CloseButton />
      </Group>
      <Text px="md" mb="md">
        Select drills from the list below to add to your practice.
      </Text>
    </>
  );
};

const ModalBody = ({ category: defaultCategory, viewingDrill, localSelectedDrills, setViewingDrill, handleSelectDrills }) => {
  const isMobile = useMediaQuery('sm');
  const scrollAreaRef = useRef(null);
  const { getValues } = usePracticeFormContext();
  const { shooters = 3, level = 3, template } = getValues();
  const [filters, setFilters] = useState(() => ({
    categoryId: defaultCategory?.id === 'custom' ? null : defaultCategory?.id,
    search: null,
    shooters,
    level,
  }));

  const handleViewDrill = (drill) => {
    setViewingDrill(drill);
    scrollAreaRef.current?.scrollTo({ top: 0 });
  };

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <Modal.Body bg="gray.0">
      <ScrollArea
        h="100%"
        type={isMobile ? 'scroll' : 'auto'}
        scrollbars="y"
        flex={1}
        px="md"
        style={{ display: isMobile && viewingDrill && 'none' }}
      >
        <FilterToolbar template={template} filters={filters} handleFilterChange={handleFilterChange} />
        <DrillList
          categoryId={filters.categoryId}
          search={filters.search}
          level={filters.level}
          shooters={filters.shooters}
          selectedDrills={localSelectedDrills}
          handleClick={handleSelectDrills}
          handleView={handleViewDrill}
        />
      </ScrollArea>
      {viewingDrill && (
        <ScrollArea viewportRef={scrollAreaRef} h="100%" type={isMobile ? 'scroll' : 'auto'} scrollbars="y" flex={1} px="sm">
          {!isMobile ? (
            <Box p="md" pos="relative">
              <CloseButton
                size="lg"
                onClick={() => setViewingDrill(null)}
                pos="absolute"
                top={35}
                right={35}
                style={{ zIndex: 1 }}
              />
              <DrillDetails drill={viewingDrill} hideEdit />
            </Box>
          ) : (
            <DrillDetails drill={viewingDrill} hideEdit my="sm" />
          )}
        </ScrollArea>
      )}
    </Modal.Body>
  );
};

const DrillSelectModal = ({ category, required, selectedDrills, isDragging, handleDrillSelect }) => {
  const isMobile = useMediaQuery('sm');
  const [opened, { open, close }] = useDisclosure(false);
  const [localSelectedDrills, setLocalSelectedDrills] = useState(selectedDrills);
  const [viewingDrill, setViewingDrill] = useState(null);

  const addDisabled =
    (!selectedDrills.length && !localSelectedDrills.length) || (required && localSelectedDrills.length > required);

  useEffect(() => {
    setLocalSelectedDrills(selectedDrills);
  }, [selectedDrills]);

  const handleSelectDrills = (drill) => {
    setLocalSelectedDrills((prevDrills) =>
      prevDrills.some((d) => d.id === drill.id) ? prevDrills.filter((d) => d.id !== drill.id) : [...prevDrills, drill],
    );
  };

  const handleConfirm = () => {
    handleDrillSelect(localSelectedDrills);
    close();
  };

  const handleClose = () => {
    setLocalSelectedDrills(selectedDrills);
    setViewingDrill(null);
    close();
  };

  const isViewingDrillMobile = isMobile && viewingDrill;

  return (
    <>
      <Button
        variant="default"
        mih={150}
        h="100%"
        w="auto"
        onClick={open}
        style={{ opacity: isDragging ? 0 : 1, transition: 'opacity .3s' }}
      >
        <IconPlus size={48} color="var(--mantine-primary-color-filled)" />
      </Button>
      <Modal.Root
        opened={opened}
        onClose={handleClose}
        size="auto"
        centered
        fullScreen={isMobile}
        classNames={{ body: classes.modalBody, content: classes.modalContent }}
      >
        <Modal.Overlay />
        <Modal.Content>
          <ModalHeader showBack={isViewingDrillMobile} handleBack={() => setViewingDrill(null)} />
          <Divider />
          <ModalBody
            category={category}
            viewingDrill={viewingDrill}
            localSelectedDrills={localSelectedDrills}
            setViewingDrill={setViewingDrill}
            handleSelectDrills={handleSelectDrills}
          />
          <Divider />
          {!isViewingDrillMobile && (
            <Group p="sm" justify="space-between">
              <Button size="sm" variant="outline" onClick={handleClose}>
                Cancel
              </Button>
              <Button size="sm" onClick={handleConfirm} disabled={addDisabled}>
                Save ({localSelectedDrills.length}
                {required ? `/${required}` : ''})
              </Button>
            </Group>
          )}
        </Modal.Content>
      </Modal.Root>
    </>
  );
};

export default DrillSelectModal;
