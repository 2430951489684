import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Card,
  CardSection,
  Group,
  Modal,
  ScrollArea,
  SimpleGrid,
  Stack,
  Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { DndContext, closestCenter, useSensor, useSensors, MouseSensor, TouchSensor } from '@dnd-kit/core';
import { arrayMove, SortableContext, rectSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
import { IconEye, IconGripVertical, IconTrash } from '@tabler/icons-react';

import { usePracticeFormContext } from '../PracticeFormContext';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { formatCategory } from '../../../utils/util';

import { fetchTemplateMembers } from './supabase';

import FormStepError from './FormStepError';
import DrillSelectModal from './DrillSelectModal';
import { DrillDetails } from '../../drills/DrillShow';
import { Section } from './StepTwo';

const dedupeBy = (arr, key = 'id') => {
  const map = new Map();
  arr.forEach((item) => {
    if (!map.has(item[key])) {
      map.set(item[key], item);
    }
  });
  return Array.from(map.values());
};

const getSelectedDrillsCount = (categoriesOrder, categoryId, drills) => {
  if (!categoriesOrder || !categoryId) return 0;
  const categoryIndex = categoriesOrder.indexOf(categoryId);
  if (categoryIndex === -1) return 0;

  let selectedDrillsCount = 0;

  for (let i = 0; i < categoryIndex; i++) {
    const category = categoriesOrder[i];
    if (drills[category]) {
      selectedDrillsCount += drills[category].length;
    }
  }

  return selectedDrillsCount;
};

const DrillSlot = ({ order, drill, onRemove }) => {
  const [opened, { open, close }] = useDisclosure();
  const isMobile = useMediaQuery('sm');
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: drill.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    boxShadow: isDragging ? 'var(--mantine-shadow-xl)' : undefined,
    border: isDragging ? '1px solid var(--mantine-color-gray-6)' : undefined,
    zIndex: isDragging ? 1 : undefined,
    cursor: isDragging ? 'grabbing' : 'grab',
  };

  if (!drill) return null;

  return (
    <>
      <Card mih={150} style={style} withBorder ref={setNodeRef} {...attributes} {...listeners}>
        <Stack h="100%" justify="space-between">
          <CardSection px="xs" pt="xs">
            <Group gap="xs">
              <IconGripVertical size={18} />
              <Badge size="md" variant="default" ml="auto">
                # {order}
              </Badge>
              <ActionIcon size="sm" variant="subtle" onClick={onRemove} c="red">
                <IconTrash size={18} />
              </ActionIcon>
            </Group>
          </CardSection>
          <Text size="md" fw={500} ta="center" style={{ textWrap: 'balance' }} px="md">
            {drill.name}
          </Text>
          <Group justify="center">
            <Button size="xs" onClick={open} leftSection={<IconEye size={18} />} px="md">
              View
            </Button>
          </Group>
        </Stack>
      </Card>
      <Modal
        opened={opened}
        onClose={close}
        scrollAreaComponent={ScrollArea.Autosize}
        title={
          <Text size="xl" fw={500}>
            Drill Preview
          </Text>
        }
        fullScreen={isMobile}
        size="xl"
      >
        <DrillDetails drill={drill} hideEdit my="sm" />
      </Modal>
    </>
  );
};

const CategoryDrills = ({ category, required }) => {
  const { getValues, setValues, errors } = usePracticeFormContext();
  const [isDragging, setIsDragging] = useState(false);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
  );

  const { drills, categoriesOrder } = getValues();
  const categoryId = category?.id;
  const selectedDrills = drills[categoryId] || [];
  const selectedDrillsCount = getSelectedDrillsCount(categoriesOrder, categoryId, drills);

  const selected = selectedDrills?.length;
  const isComplete = selected === required;
  const isError = errors['drills'];

  const handleDrillSelect = (newDrills) => {
    const updatedDrills = dedupeBy(newDrills).slice(0, required);
    setValues({ drills: { ...drills, [categoryId]: updatedDrills } });
  };

  const handleRemoveDrill = (index) => {
    setValues({
      drills: { ...drills, [categoryId]: selectedDrills.filter((drill) => drill.id !== selectedDrills[index].id) },
    });
  };

  const handleDragStart = ({ active }) => {
    if (!active) return;
    setIsDragging(true);
  };

  const handleDragEnd = ({ active, over }) => {
    if (!active || !over) return;

    const oldIndex = selectedDrills.findIndex((drill) => drill.id === active.id);
    const newIndex = selectedDrills.findIndex((drill) => drill.id === over.id);
    const reorderedDrills = arrayMove(selectedDrills, oldIndex, newIndex);

    setValues({ drills: { ...drills, [categoryId]: reorderedDrills } });
    setIsDragging(false);
  };

  return (
    <Box key={categoryId} mb="lg">
      <Group gap="xs" justify="space-between" mb={4}>
        {categoryId === 'custom' ? (
          <Group gap="xs">
            <Text size="md" fw={500}>
              Drills
            </Text>
            {!!selected && (
              <Badge variant="light" size="md">
                {selectedDrills?.length}
              </Badge>
            )}
          </Group>
        ) : (
          <Group gap="xs">
            <Text size="md" fw={500} c={isError && !isComplete ? 'red' : 'dark.9'}>
              {formatCategory(category)}
            </Text>
            <Badge variant="light" size="md" color={isComplete ? 'green.6' : selected ? 'yellow.6' : 'inherit'}>
              {`${selected} / ${required}`}
            </Badge>
          </Group>
        )}
        {selected && (
          <Button size="compact-sm" variant="subtle" onClick={() => handleDrillSelect([])}>
            Clear
          </Button>
        )}
      </Group>
      <Section>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          modifiers={[restrictToParentElement]}
        >
          <SimpleGrid type="container" cols={{ base: 1, '400px': 2 }} gap="lg">
            <SortableContext items={selectedDrills} strategy={rectSortingStrategy}>
              {Array.from({ length: required || selectedDrills?.length + 1 }).map((_, index) => {
                const drill = selectedDrills?.[index];
                return drill ? (
                  <DrillSlot
                    key={`drill-${drill.id}`}
                    order={selectedDrillsCount + index + 1}
                    drill={drill}
                    onRemove={() => handleRemoveDrill(index)}
                  />
                ) : (
                  <DrillSelectModal
                    key={`modal-${index}`}
                    category={category}
                    required={required}
                    selectedDrills={selectedDrills}
                    handleDrillSelect={handleDrillSelect}
                    isDragging={isDragging}
                  />
                );
              })}
            </SortableContext>
          </SimpleGrid>
        </DndContext>
      </Section>
    </Box>
  );
};

export default function StepFour() {
  const { getValues, setValues } = usePracticeFormContext();
  const { template: templateId } = getValues();

  const isCustom = templateId === null;

  const { data: templateMembers, isLoading } = useQuery({
    queryKey: ['templateMember', templateId],
    queryFn: () => fetchTemplateMembers(templateId),
    initialData: [{ category: { id: 'custom' } }],
    enabled: !!templateId,
  });

  useEffect(() => {
    if (isLoading) return;

    const categoriesOrder = templateMembers.map((member) => member.category_id || 'custom');
    const requiredDrills = templateMembers?.reduce((count, member) => member.count + count, 0);

    setValues({ categoriesOrder, requiredDrills });
  }, [templateMembers, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return Array.from({ length: isCustom ? 1 : 3 }).map((_, index) => <Section key={index} h={175} mb="lg" />);
  }

  if (!isCustom && !templateMembers) return null;

  return (
    <>
      <FormStepError type="drills" />
      {templateMembers.map((member) => (
        <CategoryDrills key={member?.id || 'custom'} category={member?.category} required={member?.count} />
      ))}
    </>
  );
}
