import { useMemo, useState } from 'react';
import { Container, Grid, Group } from '@mantine/core';

import PageHeader from '../components/common/PageHeader';
import DataTable from '../components/common/DataTable';
import SearchInput from '../components/common/inputs/SearchInput';
import { AddCategoryDialogButton, EditCategoryDialogButton } from '../components/category/CategoryDialog';
import { DeleteModalButton } from '../components/common/DeleteModal';

const createFilterObject = (filters) => {
  const filterObject = {};

  if (filters.search) {
    filterObject.or = [{ name: { ilike: `%${filters.search}%` } }, { group: { ilike: `%${filters.search}%` } }];
  }

  return filterObject;
};

const FilterToolbar = ({ filters, setFilters }) => {
  const handleFiltersChange = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  return (
    <Grid type="container" breakpoints={{ xs: '576px', sm: '768px', md: '992px', lg: '1200px', xl: '1400px' }} mb="lg">
      <Grid.Col span={12}>
        <SearchInput
          label="Search"
          placeholder="Search by name or group"
          initialValue={filters.search}
          onSearch={(value) => handleFiltersChange('search', value)}
        />
      </Grid.Col>
    </Grid>
  );
};

export default function AdminCategories() {
  const [filters, setFilters] = useState({});

  const filter = createFilterObject(filters);

  const columns = useMemo(
    () => [
      {
        accessor: 'name',
      },
      {
        accessor: 'group',
        render: ({ group }) => group || '-',
      },
      {
        accessor: 'created_at',
        title: 'Created At',
      },
      {
        accessor: 'actions',
        title: '',
        textAlign: 'right',
        render: (record) => (
          <Group justify="right" wrap="no-wrap">
            <EditCategoryDialogButton initialValues={record} />
            <DeleteModalButton record={record} />
          </Group>
        ),
      },
    ],
    [],
  );

  return (
    <Container size="xl" p={0}>
      <PageHeader title="Categories" actions={[<AddCategoryDialogButton key="create-category" />]} />
      <FilterToolbar filters={filters} setFilters={setFilters} />
      <DataTable columns={columns} filter={filter} sortBy="group" />
    </Container>
  );
}
