import { useEffect, useRef, useState } from 'react';
import { Badge, Button, Card, Center, Container, Group, Image, Pagination, SimpleGrid, Skeleton, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';

import supabase from '../config/supabaseClient';

import PageHeader from '../components/common/PageHeader';

const PAGE_SIZE = 10;
const LINE_CLAMP = 8;

const useShowMoreButton = (content, lineClamp) => {
  const [show, setShow] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const textElement = textRef.current;
    const calculateShowButton = () => {
      const lineHeight = parseInt(window.getComputedStyle(textElement).lineHeight, 10);
      const maxHeight = lineHeight * lineClamp + 1;

      if (textElement.scrollHeight > maxHeight) {
        setShow(true);
      } else {
        setShow(false);
      }
    };

    calculateShowButton();
    window.addEventListener('resize', calculateShowButton);

    return () => {
      window.removeEventListener('resize', calculateShowButton);
    };
  }, [content, lineClamp]);

  return { textRef, show };
};

const useAnnouncements = (page) =>
  useQuery({
    queryKey: ['announcements', { page }],
    queryFn: async () =>
      supabase
        .from('announcement')
        .select('*', { count: 'exact' })
        .range((page - 1) * PAGE_SIZE, page * PAGE_SIZE - 1)
        .order('created_at', { ascending: false })
        .throwOnError(),
  });

const AnnoucementCard = ({ announcement }) => {
  const [expanded, { toggle }] = useDisclosure(false);
  const { textRef, show } = useShowMoreButton(announcement.content, LINE_CLAMP);

  const date = format(new Date(announcement.created_at), 'MMM dd, yyyy');

  return (
    <Card shadow="md" withBorder>
      <Card.Section bg="dark.7">
        <Image src={announcement?.image_url} alt={announcement.name} height={140} fallbackSrc="assets/favicon.png" />
      </Card.Section>
      <Group justify="space-between" mt="md">
        <Text size="lg" fw={500}>
          {announcement.title}
        </Text>
        <Badge size="sm" variant="light">
          {date}
        </Badge>
      </Group>
      <Text ref={textRef} size="sm" mt="xs" c="dark" lineClamp={!expanded ? LINE_CLAMP : undefined}>
        {announcement.content}
      </Text>
      {show && (
        <Button size="xs" variant={expanded ? 'light' : 'filled'} onClick={toggle} mt="sm">
          {expanded ? 'Read Less' : 'Read More'}
        </Button>
      )}
    </Card>
  );
};

const AnnouncementsList = () => {
  const [page, setPage] = useState(1);
  const { data, isPending, isError } = useAnnouncements(page);

  const announcements = data?.data || [];
  const totalPages = Math.ceil(data?.count / PAGE_SIZE) || 1;
  const showPagination = totalPages > 1;

  if (!isPending && !announcements?.length)
    return (
      <Center h="400px">
        <Text align="center" size="xl" fw={500} c="dimmed">
          {isError ? 'Issue fetching announcements. Please try again.' : 'No announcements found.'}
        </Text>
      </Center>
    );

  return (
    <>
      <SimpleGrid type="container" cols={{ base: 1, '700px': 2, '1100px': 3 }} gap="lg" pb="xl">
        {isPending
          ? Array.from({ length: 6 }).map((_, index) => <Skeleton key={index} h={400} w="auto" />)
          : announcements.map((announcement) => <AnnoucementCard key={announcement.id} announcement={announcement} />)}
      </SimpleGrid>
      {showPagination && (
        <Group justify="center" gap="xs">
          <Pagination total={totalPages} value={page} onChange={setPage} />
        </Group>
      )}
    </>
  );
};

export default function Announcements() {
  return (
    <Container size="xl" p={0}>
      <PageHeader title="Announcements" />
      <AnnouncementsList />
    </Container>
  );
}
