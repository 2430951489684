import { useState } from 'react';
import { Loader, Select } from '@mantine/core';
import { useUncontrolled } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';

import supabase from '../../../config/supabaseClient';

const useTemplates = (enabled = true) =>
  useQuery({
    queryKey: ['templates'],
    queryFn: async () => await supabase.from('template').select().order('id').throwOnError(),
    select: ({ data }) => data.map((template) => ({ value: String(template.id), label: template.name })),
    enabled,
  });

const TemplateSelect = ({ value, defaultValue, onChange, defaultEnabled = true, ...props }) => {
  const [enabled, setEnabled] = useState(defaultEnabled);
  const { data, isLoading } = useTemplates(enabled);
  const [_value, setValue] = useUncontrolled({ value, defaultValue, onChange });

  return (
    <Select
      value={_value && String(_value)}
      onChange={setValue}
      data={data}
      label="Template"
      placeholder="Select a template"
      rightSection={isLoading && <Loader size={18} />}
      searchable
      clearable
      onDropdownOpen={() => {
        if (!enabled) {
          setEnabled(true);
        }
      }}
      {...props}
    />
  );
};

export default TemplateSelect;
