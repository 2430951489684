import { useState } from 'react';
import { Loader, Select } from '@mantine/core';
import { useUncontrolled } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';
import { IconActivity } from '@tabler/icons-react';

import supabase from '../../../config/supabaseClient';

const useExperiences = (enabled = true) =>
  useQuery({
    queryKey: ['experiences'],
    queryFn: async () => await supabase.from('experience').select().order('id').throwOnError(),
    select: ({ data }) => data.map((experience) => ({ value: String(experience.id), label: experience.name })),
    enabled,
  });

const ExperienceSelect = ({ value, defaultValue, onChange, defaultEnabled = true, ...props }) => {
  const [enabled, setEnabled] = useState(defaultEnabled);
  const { data, isLoading } = useExperiences(enabled);
  const [_value, setValue] = useUncontrolled({ value, defaultValue, onChange });

  return (
    <Select
      value={_value && String(_value)}
      onChange={setValue}
      data={data}
      label="Experience"
      placeholder="Select an experience"
      leftSection={<IconActivity size={18} />}
      rightSection={isLoading && <Loader size={18} />}
      searchable
      clearable
      onDropdownOpen={() => {
        if (!enabled) {
          setEnabled(true);
        }
      }}
      {...props}
    />
  );
};

export default ExperienceSelect;
