import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ActionIcon, Button, Modal, ScrollArea, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { upperFirst } from '@mantine/hooks';
import { IconEdit, IconPlus } from '@tabler/icons-react';

import supabase from '../../config/supabaseClient';
import useResource from '../../hooks/useResource';

const OpenModalButton = ({ size = 'sm', onClick, icon: Icon, iconColor, iconOnly = false, openLabel }) =>
  iconOnly ? (
    <ActionIcon size="md" variant="subtle" onClick={onClick}>
      <Icon color={iconColor} />
    </ActionIcon>
  ) : (
    <Button size={size} leftSection={<Icon size={16} color={iconColor} stroke={3} />} onClick={onClick}>
      {openLabel.toUpperCase()}
    </Button>
  );

export const OpenAddModalButton = ({ size, onClick, iconOnly, openLabel = 'Create', iconColor }) => (
  <OpenModalButton
    size={size}
    onClick={onClick}
    icon={IconPlus}
    iconOnly={iconOnly}
    openLabel={openLabel}
    iconColor={iconColor}
  />
);
export const OpenEditModalButton = ({ onClick, iconOnly, openLabel = 'Edit', iconColor = 'orange' }) => (
  <OpenModalButton onClick={onClick} icon={IconEdit} iconOnly={iconOnly} openLabel={openLabel} iconColor={iconColor} />
);

const formatResource = (resource) =>
  resource
    .split('_')
    .map((word) => upperFirst(word))
    .join(' ');

export const CreateModal = ({
  resource: providedResource,
  initialValues,
  submitFn,
  size,
  redirect = null,
  opened,
  onClose,
  children,
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const resource = useResource(providedResource);

  const handleMutate = async (values) => {
    if (submitFn) {
      return await submitFn(values, resource);
    } else {
      return await supabase
        .from(resource)
        .insert(values)
        .select()
        .single()
        .throwOnError()
        .then((res) => res.data);
    }
  };

  const handleSuccess = (data) => {
    queryClient.invalidateQueries(resource);
    queryClient.setQueryData([resource, data?.id], data);

    notifications.show({
      title: `${formatResource(resource)} created`,
      message: `${formatResource(resource)} has been successfully created`,
      color: 'green',
    });

    if (redirect) {
      navigate(redirect);
    }
  };

  const handleError = (error) => {
    console.log('error :>> ', error);
    notifications.show({
      title: 'Error',
      message: `An error occurred while creating the ${resource}`,
      color: 'red',
    });
  };

  return (
    <ResourceModal
      resource={resource}
      initialValues={initialValues}
      handleMutate={handleMutate}
      handleSuccess={handleSuccess}
      handleError={handleError}
      title={`Create ${formatResource(resource)}`}
      submitLabel="Create"
      size={size}
      opened={opened}
      onClose={onClose}
    >
      {children}
    </ResourceModal>
  );
};

export const EditModal = ({
  resource: providedResource,
  submitFn,
  redirect = null,
  initialValues = {},
  size,
  opened,
  onClose,
  children,
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const resource = useResource(providedResource);

  const { data: record, isFetching } = useQuery({
    queryKey: [resource, initialValues.id, 'edit'],
    queryFn: async () =>
      await supabase
        .from(resource)
        .select()
        .eq('id', initialValues.id)
        .single()
        .throwOnError()
        .then((res) => res.data),
    placeholderData: initialValues,
    enabled: !!initialValues.id && opened,
  });

  const handleMutate = async (values) => {
    if (submitFn) {
      await submitFn(values, record, resource);
    } else {
      await supabase.from(resource).update(values).eq('id', values.id).select().throwOnError();
    }
  };

  const handleSuccess = (data) => {
    queryClient.invalidateQueries(resource);
    queryClient.setQueryData([resource, record.id], data);

    notifications.show({
      title: `${formatResource(resource)} modified`,
      message: `${formatResource(resource)} has been successfully modified`,
      color: 'green',
    });

    if (redirect) {
      navigate(redirect);
    }
  };

  const handleError = (error) => {
    console.log('error :>> ', error);
    notifications.show({
      title: 'Error',
      message: `An error occurred while editing the ${resource}`,
      color: 'red',
    });
  };

  return (
    <ResourceModal
      resource={resource}
      initialValues={record}
      handleMutate={handleMutate}
      handleSuccess={handleSuccess}
      handleError={handleError}
      title={`Edit ${formatResource(resource)}`}
      submitLabel="Save"
      size={size}
      opened={opened}
      onClose={onClose}
      loading={isFetching}
    >
      {children}
    </ResourceModal>
  );
};

const ResourceModal = ({
  resource: providedResource,
  initialValues = {},
  handleMutate,
  handleSuccess,
  handleError,
  title,
  submitLabel,
  cancelLabel = 'Cancel',
  size = 'lg',
  opened,
  onClose,
  loading,
  children,
}) => {
  const resource = useResource(providedResource);

  const mutation = useMutation({
    mutationFn: handleMutate,
    onSuccess: (data) => {
      handleSuccess(data);
      onClose();
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const handleSubmit = (values) => {
    mutation.mutate(values);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      opened={opened}
      onClose={handleClose}
      title={
        <Text size="lg" fw={500}>
          {title}
        </Text>
      }
      centered
      size={size}
      scrollAreaComponent={ScrollArea.Autosize}
      closeOnClickOutside={false}
    >
      {React.cloneElement(React.Children.only(children), {
        key: JSON.stringify(initialValues), // Force re-render when initialValues changes
        resource,
        initialValues,
        onSubmit: handleSubmit,
        onClose: handleClose,
        toolbarProps: {
          submitLabel,
          cancelLabel,
          loading: mutation.isPending || loading,
        },
      })}
    </Modal>
  );
};
