import { Box, Button, CloseIcon, Grid } from '@mantine/core';

import useMediaQuery from '../../hooks/useMediaQuery';
import CategorySelect from '../common/inputs/CategorySelect';
import LevelSelect from '../common/inputs/LevelSelect';
import SearchInput from '../common/inputs/SearchInput';
import ShootersSelect from '../common/inputs/ShootersSelect';

import { useDrillsContext } from './DrillsProvider';

const inputProps = {
  size: 'md',
  comboboxProps: { shadow: 'xl' },
};

const DesktopFilters = () => {
  const isMobile = useMediaQuery('sm');
  const { filters, hasFilters, handleFiltersChange, clearFilters } = useDrillsContext();

  if (isMobile) return null;

  return (
    <Box mb="lg">
      <Grid gutter="xs" type="container" breakpoints={{ xs: '576px', sm: '768px', md: '992px', lg: '1200px', xl: '1400px' }}>
        <Grid.Col span={{ base: 12, md: 4 }}>
          <SearchInput
            label="Search"
            placeholder="Search drills"
            initialValue={filters.name}
            onSearch={(value) => handleFiltersChange('name', value)}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, xs: 6, md: 4 }}>
          <CategorySelect
            defaultEnabled={false}
            value={filters.category}
            onChange={(value) => handleFiltersChange('category', value)}
            {...inputProps}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 6, xs: 3, md: 2 }}>
          <LevelSelect value={filters.level} onChange={(value) => handleFiltersChange('level', value)} {...inputProps} />
        </Grid.Col>
        <Grid.Col span={{ base: 6, xs: 3, md: 2 }}>
          <ShootersSelect value={filters.shooters} onChange={(value) => handleFiltersChange('shooters', value)} {...inputProps} />
        </Grid.Col>
      </Grid>
      {hasFilters && (
        <Button size="xs" color="red" mt="sm" onClick={clearFilters} leftSection={<CloseIcon size={16} />}>
          Clear
        </Button>
      )}
    </Box>
  );
};

export default DesktopFilters;
