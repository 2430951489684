import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Group, Text } from '@mantine/core';
import { DataTable as MantineDataTable } from 'mantine-datatable';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { IconAlertCircle } from '@tabler/icons-react';
import { format } from 'date-fns';

import supabase from '../../config/supabaseClient';
import useResource from '../../hooks/useResource';

import { DeleteModalButton } from './DeleteModal';

const isDev = import.meta.env.DEV;

const PAGE_SIZES = [isDev && 1, 10, 25, 50].filter(Boolean);

const applyFilters = (query, filters) => {
  Object.keys(filters).forEach((field) => {
    if (field === 'or') {
      const orFilters = filters[field];
      const orStatement = orFilters
        .map((filter) => {
          const field = Object.keys(filter)[0];
          const condition = Object.keys(filter[field])[0];
          const value = filter[field][condition];
          return `${field}.${condition}.${value}`;
        })
        .join(',');
      query = query.or(orStatement);
    } else {
      const condition = Object.keys(filters[field])[0];
      const value = filters[field][condition];
      query = query[condition](field, value);
    }
  });
  return query;
};

const formatColumns = (columns) =>
  columns.map((column) => {
    const accessor = column.accessor;
    if (['created_at', 'updated_at', 'date'].includes(accessor) && !column.render) {
      return {
        ...column,
        render: (column) => format(new Date(column[accessor]), 'MMM dd, yyyy'),
      };
    }

    return column;
  });

// Generate columns based on the first record if no columns are provided
const generateColumns = (records) => {
  if (!records?.length) return [];

  const firstRecord = records[0];
  const columns = Object.keys(firstRecord)
    .filter((key) => key !== 'id' && !key.includes('_id'))
    .map((key) => {
      if (typeof firstRecord[key] === 'object' && firstRecord[key] !== null) {
        return {
          accessor: `${key}.name`,
          title: key.charAt(0).toUpperCase() + key.slice(1),
        };
      }
      return {
        accessor: key,
        title: key.charAt(0).toUpperCase() + key.slice(1),
      };
    });

  const actionColumns = [];
  const dateColumns = [];
  const nameColumns = [];
  const otherColumns = [];

  columns.forEach((column) => {
    const accessor = column.accessor;
    if (['created_at', 'updated_at', 'date'].includes(accessor)) {
      dateColumns.push(column);
    } else if (accessor.includes('name')) {
      nameColumns.push(column);
    } else if (accessor === 'actions') {
      actionColumns.push(column);
    } else {
      otherColumns.push(column);
    }
  });

  actionColumns.push({
    accessor: 'actions',
    title: '',
    textAlign: 'right',
    render: ({ id, name }) => <DeleteModalButton record={{ id, name }} />,
  });

  return [...nameColumns, ...otherColumns, ...dateColumns, ...actionColumns];
};

const NoColumnsMessage = ({ show = false }) => {
  const [showAlert, setShowAlert] = useState(show);

  if (!showAlert || !isDev) return null;
  return (
    <Alert
      color="orange"
      title={
        <Group gap="xs">
          <IconAlertCircle size={22} />
          <Text size="lg" fw={500}>
            No columns provided
          </Text>
        </Group>
      }
      mb="md"
      withCloseButton
      onClose={() => setShowAlert(false)}
    >
      These columns have been generated automatically. Please ensure you provide the correct columns for production.
    </Alert>
  );
};

export default function DataTable({
  select = '*',
  columns = [],
  sortBy = 'created_at',
  sortAscending = false,
  enableRowClick = false,
  filter,
  noResults: NoResults,
  handleRedirect,
  rowExpand: RowExpand,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const resource = useResource();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZES[1]);

  const locationState = { page, pageSize, filter };

  const { data, isPending, isError, error } = useQuery({
    queryKey: [resource, locationState],
    queryFn: async () => {
      let query = supabase.from(resource).select(select, { count: 'exact' });

      if (filter) {
        query = applyFilters(query, filter);
      }

      return query
        .order(sortBy, { ascending: sortAscending })
        .range((page - 1) * pageSize, page * pageSize - 1)
        .throwOnError()
        .then(({ data, count }) => ({ data, count }));
    },
    placeholderData: keepPreviousData,
  });

  const { data: records, count } = data || {};

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setPage(1);
  };

  const redirect = ({ record }) => {
    if (handleRedirect) {
      handleRedirect(record, locationState);
    } else {
      navigate(`${location.pathname}/${record.id}`, { state: locationState });
    }
  };

  let hasGeneratedColumns = false;
  if (!columns?.length) {
    hasGeneratedColumns = true;
    columns = generateColumns(records);
  }

  if (!records?.length && !isPending && NoResults) {
    return <NoResults />;
  }

  return (
    <>
      <NoColumnsMessage show={hasGeneratedColumns} />
      <MantineDataTable
        columns={formatColumns(columns)}
        records={records}
        fetching={isPending}
        minHeight={isPending || isError || !records?.length ? 250 : 'auto'}
        emptyState={
          <Text c="dimmed" size="sm">
            {isError ? error?.message : 'No records found'}
          </Text>
        }
        noRecordsText=""
        withTableBorder
        borderRadius="md"
        shadow="sm"
        verticalSpacing="md"
        totalRecords={count}
        recordsPerPage={pageSize}
        recordsPerPageOptions={PAGE_SIZES}
        onRecordsPerPageChange={handlePageSizeChange}
        page={page}
        onRowClick={enableRowClick ? redirect : undefined}
        onPageChange={setPage}
        highlightOnHover={enableRowClick}
        defaultColumnProps={{
          ellipsis: true,
        }}
        rowExpansion={
          RowExpand ? { content: ({ record, collapse }) => <RowExpand record={record} collapse={collapse} /> } : undefined
        }
      />
    </>
  );
}
