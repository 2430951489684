import { useState } from 'react';
import {
  Accordion,
  ActionIcon,
  Alert,
  Badge,
  Button,
  Card,
  Center,
  Container,
  Group,
  List,
  Menu,
  Modal,
  rem,
  Skeleton,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  IconChevronLeft,
  IconChevronRight,
  IconCircleCheck,
  IconCircleDashed,
  IconEdit,
  IconList,
  IconDotsVertical,
  IconPlayerPlay,
  IconX,
  IconActivity,
  IconClockHour3,
} from '@tabler/icons-react';
import { format } from 'date-fns';

import supabase from '../../config/supabaseClient';
import { EXPERIENCE_COLOR } from '../../constants';
import useInitialData from '../../hooks/useInitialData';
import { useUserInfo } from '../../hooks/useUserInfo';
import { useUser } from '../../context/SessionContext';
import { DrillIcon } from '../../icons';

import CustomCard from '../common/CustomCard';
import { DeleteModalMenuItem } from '../common/DeleteModal';
import PageAnchor from '../common/BackAnchor';
import StopPropagation from '../common/StopPropagation';

import { DrillBadges, DrillDetails } from '../drills/DrillShow';
import FavouritePracticeButton from './FavouritePracticeButton';
import SharePractice from './SharePractice';

import classes from './Practice.module.css';

const ActionButton = ActionIcon.withProps({ size: 'lg', variant: 'subtle' });

export const SharedIcon = () => {
  return (
    <ThemeIcon color="teal" size={24} radius="xl">
      <IconCircleCheck style={{ width: rem(16), height: rem(16) }} />
    </ThemeIcon>
  );
};

export const PendingShareIcon = () => {
  return (
    <ThemeIcon color="blue" size={24} radius="xl">
      <IconCircleDashed style={{ width: rem(16), height: rem(16) }} />
    </ThemeIcon>
  );
};

export const PracticeOwner = ({ practice }) => {
  const { data: userInfo } = useUserInfo(practice?.user_id);

  if (!userInfo) return null;

  return (
    <Stack gap={0}>
      <Text size="xs">{userInfo?.name}</Text>
      <Text size="xs" c="dimmed">
        {userInfo?.email}
      </Text>
    </Stack>
  );
};

const SharedByBanner = ({ practice }) => {
  const { data: userInfo } = useUserInfo(practice?.user_id);

  if (!userInfo) return null;
  return (
    <Alert color="snh" title="Shared Practice" mb="md" autoContrast>
      <Text size="sm">{`This practice has been shared to you by ${userInfo?.name} (${userInfo?.email})`}</Text>
    </Alert>
  );
};

const SharedWith = ({ practiceId, sharedPractices: initialData }) => {
  const user = useUser();

  const { data: sharedPractices, isPending } = useQuery({
    queryKey: ['shared_practice', practiceId],
    queryFn: () =>
      supabase
        .from('shared_practice')
        .select('*')
        .eq('practice_id', practiceId)
        .eq('owner_user_id', user.id)
        .then(({ data }) => data),
    initialData,
  });

  if (!sharedPractices?.length || isPending) return null;

  return (
    <CustomCard title="Shared with" height="auto" mt="md">
      <List spacing="xs" size="sm" center>
        {(sharedPractices || []).map((shared) => (
          <List.Item key={shared.id} icon={shared.shared_user_id ? <SharedIcon /> : <PendingShareIcon />}>
            {shared.shared_email}
            <Text size="xs" c="dimmed">
              {format(shared.created_at, 'MMM dd, yyyy')}
            </Text>
          </List.Item>
        ))}
      </List>
    </CustomCard>
  );
};

const DrillSelectModal = ({ isOpen, drills, activeDrill, setActiveDrill, onClose }) => {
  const handleSelect = (drill) => {
    setActiveDrill(drill);
    onClose();
  };

  return (
    <Modal title="All Drills" opened={isOpen} onClose={onClose} centered>
      <List>
        {drills.map((drill) => (
          <List.Item
            key={drill?.id}
            p="xs"
            onClick={() => handleSelect(drill)}
            icon={
              <Badge circle variant="default">
                {drill.order}
              </Badge>
            }
            mod={{ selected: drill.id === activeDrill?.id }}
            className={classes.drillListItem}
          >
            <Text>{drill.name}</Text>
          </List.Item>
        ))}
      </List>
    </Modal>
  );
};

const DrillsListHeader = ({ activeDrill, setActiveDrill, drills, onOpen }) => {
  const { id, name, order } = activeDrill;

  const handleDrillChangeForward = () => {
    const index = drills.findIndex((drill) => drill.id === activeDrill.id);
    setActiveDrill(drills[index - 1]);
  };

  const handleDrillChangeBackward = () => {
    const index = drills.findIndex((drill) => drill.id === activeDrill.id);
    setActiveDrill(drills[index + 1]);
  };

  return (
    <>
      <Group justify="space-between" mb="lg">
        <Badge variant="default">{`Drill ${order} of ${drills.length}`}</Badge>
        <Button
          size="compact-sm"
          variant="subtle"
          onClick={onOpen}
          leftSection={<IconList size={16} />}
          classNames={{ section: classes.buttonSection }}
        >
          View All
        </Button>
      </Group>
      <Group justify="space-between" wrap="no-wrap" align="flex-start">
        <ActionButton onClick={handleDrillChangeForward} disabled={drills.findIndex((drill) => drill.id === id) === 0}>
          <IconChevronLeft size={28} />
        </ActionButton>
        <Text size="lg" fw={700} ta="center" style={{ textWrap: 'pretty' }}>
          {name}
        </Text>
        <StopPropagation>
          <Group gap={4} wrap="nowrap">
            <ActionButton
              onClick={handleDrillChangeBackward}
              disabled={drills.findIndex((drill) => drill.id === id) === drills.length - 1}
            >
              <IconChevronRight size={28} />
            </ActionButton>
          </Group>
        </StopPropagation>
      </Group>
    </>
  );
};

const DrillTabs = ({ drills }) => {
  const [opened, { open, close }] = useDisclosure();
  const [activeDrill, setActiveDrill] = useState(drills[0]);

  return (
    <>
      <Card shadow="sm" withBorder mb="sm">
        <DrillsListHeader
          isOpen={opened}
          drills={drills}
          activeDrill={activeDrill}
          setActiveDrill={setActiveDrill}
          onOpen={open}
        />
      </Card>
      <DrillDetails drill={activeDrill} hideEdit />
      <DrillSelectModal
        isOpen={opened}
        drills={drills}
        activeDrill={activeDrill}
        setActiveDrill={setActiveDrill}
        onClose={close}
      />
    </>
  );
};

const DrillsAccordion = ({ drills, practiceId }) => {
  const navigate = useNavigate();

  const handleRedirect = (drillId) => {
    navigate(`/drills/${drillId}`, {
      state: { from: `/practices/${practiceId}` },
    });
  };

  if (!drills.length) return null;

  return (
    <Card shadow="sm" withBorder>
      <Group gap={4} mb="sm">
        <ThemeIcon variant="subtle">
          <DrillIcon />
        </ThemeIcon>
        <Text size="xl" fw={700}>
          Drills
        </Text>
      </Group>
      <Accordion variant="separated">
        {drills.map((drill, index) => (
          <Accordion.Item key={drill.id} value={drill.name} bd="1px solid gray.3">
            <Accordion.Control
              icon={
                <Badge variant="default" circle>
                  {drill.order || index + 1}
                </Badge>
              }
            >
              {drill.name}
            </Accordion.Control>
            <Accordion.Panel>
              <DrillBadges drill={drill} mb="xs" />
              <Text c="dimmed">{drill.description}</Text>
              <Group justify="center" mt="md">
                <Button size="compact-sm" variant="subtle" onClick={() => handleRedirect(drill.id)}>
                  See More
                </Button>
              </Group>
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
    </Card>
  );
};

const PracticeDrillsSkeleton = () => {
  return (
    <Card shadow="sm" withBorder>
      <Group gap={4} mb="sm">
        <ThemeIcon variant="subtle">
          <DrillIcon />
        </ThemeIcon>
        <Text size="xl" fw={700}>
          Drills
        </Text>
      </Group>
      <Stack>
        {Array.from({ length: 6 }).map((_, index) => (
          <Skeleton key={index} height={50} />
        ))}
      </Stack>
    </Card>
  );
};

const PracticeDrillsList = ({ practiceId, isRunning }) => {
  const { data: drills, isPending } = useQuery({
    queryKey: ['practiceDrill', practiceId],
    queryFn: () =>
      supabase
        .from('practice_drill')
        .select('*, drill!inner(*, category(*))')
        .eq('practice_id', practiceId)
        .order('order')
        .throwOnError()
        .then(({ data }) => data),
    select: (data) => data.map(({ drill, order }) => ({ ...drill, order })),
  });

  if (isPending) return <PracticeDrillsSkeleton />;

  if (!drills?.length)
    return (
      <Center h={200}>
        <Text size="lg">No drills found</Text>
      </Center>
    );

  return isRunning ? <DrillTabs drills={drills} /> : <DrillsAccordion drills={drills} practiceId={practiceId} />;
};

const ActionsMenu = ({ practice }) => {
  const navigate = useNavigate();

  return (
    <Menu keepMounted>
      <Menu.Target>
        <ActionIcon size="md" variant="subtle">
          <IconDotsVertical />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item leftSection={<IconEdit size={18} />} color="orange" onClick={() => navigate(`/practices/${practice.id}/edit`)}>
          <Text size="md">Edit Practice</Text>
        </Menu.Item>
        <DeleteModalMenuItem record={practice} redirect="/practices" />
      </Menu.Dropdown>
    </Menu>
  );
};

const PracticeHeader = ({ practice, isRunning, toggleIsRunning }) => {
  const user = useUser();

  const { experience, duration } = practice.template || {};
  const isPracticeOwner = practice?.user_id === user.id;

  return (
    <Card shadow="sm" withBorder mb="md">
      <Group gap={{ base: 0, sm: 'md' }} justify="space-between" wrap="no-wrap" mb="xs">
        <Group gap="xs" wrap="no-wrap">
          <Title order={2}>{practice?.name}</Title>
          {!isRunning && isPracticeOwner && <FavouritePracticeButton practice={practice} />}
        </Group>
        {!isRunning && isPracticeOwner && <ActionsMenu practice={practice} />}
      </Group>
      {practice?.template && (
        <Group gap="xs" mb="xs">
          <Badge size="md" variant="outline" color={EXPERIENCE_COLOR[experience.name]} leftSection={<IconActivity size={14} />}>
            {experience.name}
          </Badge>
          <Badge size="md" variant="outline" leftSection={<IconClockHour3 size={14} />}>
            {duration.name}
          </Badge>
        </Group>
      )}
      <Text size="lg" c="dimmed" mb="md">
        {practice?.description}
      </Text>
      <Group>
        <Button
          color={isRunning ? 'red' : 'snh'}
          onClick={toggleIsRunning}
          size="sm"
          leftSection={isRunning ? <IconX size={16} /> : <IconPlayerPlay size={16} />}
        >
          {isRunning ? 'End' : 'Run'} Practice
        </Button>
        {!isRunning && isPracticeOwner && <SharePractice practice={practice} />}
      </Group>
    </Card>
  );
};

export default function PracticeShow() {
  const user = useUser();
  const { id } = useParams();
  const initialData = useInitialData();
  const [isRunning, { toggle }] = useDisclosure();

  const practiceId = parseInt(id);

  const { data: practice, isPending } = useQuery({
    queryKey: ['practice', practiceId],
    queryFn: () =>
      supabase
        .from('practice')
        .select('*, template (id, name, experience (name), duration (name)), shared_practice (*)')
        .eq('id', practiceId)
        .single()
        .throwOnError()
        .then(({ data }) => data),
    initialData,
    enabled: !initialData,
  });

  const isPracticeOwner = practice?.user_id === user.id;

  if (isPending) return <div>Loading...</div>;
  if (!practice) return <div>Practice not found</div>;

  return (
    <>
      <PageAnchor label="Back to Practices" to="/practices" />
      <Container size="md" p={0} mb="xl">
        <PracticeHeader practice={practice} isRunning={isRunning} toggleIsRunning={toggle} />
        {!isRunning && !isPracticeOwner && <SharedByBanner practice={practice} />}
        <PracticeDrillsList practiceId={practiceId} isRunning={isRunning} />
        {!isRunning && isPracticeOwner && <SharedWith practiceId={practiceId} sharedPractices={practice.shared_practice} />}
      </Container>
    </>
  );
}
