import { useMemo, useState } from 'react';
import { Container, Grid, Group } from '@mantine/core';

import PageHeader from '../components/common/PageHeader';
import DataTable from '../components/common/DataTable';
import ExperienceSelect from '../components/common/inputs/ExperienceSelect';
import DurationSelect from '../components/common/inputs/DurationSelect';
import SearchInput from '../components/common/inputs/SearchInput';
import { DeleteModalButton } from '../components/common/DeleteModal';

import { AddTemplateDialogButton, EditTemplateDialogButton } from '../components/template/TemplateDialog';
import TemplateMembersCard from '../components/templateMember/TemplateMembersCard';

const createFilterObject = (filters) => {
  const filterObject = {};

  if (filters.name) filterObject.name = { ilike: `%${filters.name}%` };
  if (filters.experience) filterObject.experience_id = { eq: filters.experience };
  if (filters.duration) filterObject.duration_id = { eq: filters.duration };

  return filterObject;
};

const FilterToolbar = ({ filters, setFilters }) => {
  const handleFiltersChange = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  return (
    <Grid mb="lg" type="container" breakpoints={{ xs: '576px', sm: '768px', md: '992px', lg: '1200px', xl: '1400px' }}>
      <Grid.Col span={{ base: 12, md: 4 }}>
        <SearchInput
          placeholder="Search templates"
          initialValue={filters.name}
          onSearch={(value) => handleFiltersChange('name', value)}
        />
      </Grid.Col>
      <Grid.Col span={{ base: 12, xs: 6, md: 4 }}>
        <ExperienceSelect value={filters.experience} onChange={(value) => handleFiltersChange('experience', value)} size="md" />
      </Grid.Col>
      <Grid.Col span={{ base: 12, xs: 6, md: 4 }}>
        <DurationSelect value={filters.duration} onChange={(value) => handleFiltersChange('duration', value)} size="md" />
      </Grid.Col>
    </Grid>
  );
};

export default function AdminTemplates() {
  const [filters, setFilters] = useState({});

  const filter = createFilterObject(filters);

  const select = '*, duration(name), experience(name))';

  const columns = useMemo(
    () => [
      {
        accessor: 'name',
      },
      {
        accessor: 'experience.name',
        title: 'Experience',
      },
      {
        accessor: 'duration.name',
        title: 'Duration',
      },
      {
        accessor: 'created_at',
        title: 'Created At',
      },
      {
        accessor: 'actions',
        title: '',
        textAlign: 'right',
        render: (record) => (
          <Group justify="right" wrap="no-wrap">
            <EditTemplateDialogButton initialValues={record} />
            <DeleteModalButton record={record} />
          </Group>
        ),
      },
    ],
    [],
  );

  return (
    <Container size="xl" p={0}>
      <PageHeader title="Templates" actions={[<AddTemplateDialogButton key="create-template-modal" />]} />
      <FilterToolbar filters={filters} setFilters={setFilters} />
      <DataTable select={select} columns={columns} filter={filter} rowExpand={TemplateMembersCard} sortAscending />
    </Container>
  );
}
