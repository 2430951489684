import { Box, Center, Group, Pagination, SimpleGrid, Skeleton, Text } from '@mantine/core';

import { useDrillsContext } from './DrillsProvider';
import DrillCard from './DrillCard';

export default function DrillCatalog({ handleClick }) {
  const { drills, isPending, totalPages, page, setPage, pageSize, filters } = useDrillsContext();

  if (!isPending && !drills?.length)
    return (
      <Center h="400px">
        <Text align="center" size="xl" fw={500} c="dimmed">
          No drills found
        </Text>
      </Center>
    );

  const locationState = { ...filters, page, pageSize };

  return (
    <Box mb="lg">
      <SimpleGrid type="container" cols={{ base: 1, '700px': 2, '1100px': 3 }} gap="lg" pb="xl">
        {isPending
          ? Array.from({ length: 6 }).map((_, index) => <Skeleton key={index} h={310} w="auto" />)
          : drills.map((drill) => (
              <DrillCard key={drill.id} drill={drill} handleClick={handleClick} locationState={locationState} />
            ))}
      </SimpleGrid>
      <Group justify="center" gap="xs">
        <Pagination total={totalPages} value={page} onChange={setPage} />
      </Group>
    </Box>
  );
}
