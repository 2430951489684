import { useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button, Container, Grid, Group, Paper, rem, ScrollArea, SegmentedControl, Stack, Text } from '@mantine/core';
import { IconMail, IconMailForward, IconPlus, IconStarFilled, IconUser } from '@tabler/icons-react';

import { EXPERIENCE_COLOR } from '../constants';
import { useUser } from '../context/SessionContext';
import useMediaQuery from '../hooks/useMediaQuery';

import { PracticeOwner } from '../components/practices/PracticeShow';
import PageHeader from '../components/common/PageHeader';
import DataTable from '../components/common/DataTable';
import SearchInput from '../components/common/inputs/SearchInput';
import LevelSelect from '../components/common/inputs/LevelSelect';
import DurationSelect from '../components/common/inputs/DurationSelect';
import TemplateSelect from '../components/common/inputs/TemplateSelect';
import MobileFiltersDrawer from '../components/common/MobileFiltersDrawer';

const MY_PRACTICES = 'My Practices';
const SHARED_WITH_YOU = 'Shared With You';
const SHARED_WITH_OTHERS = 'Shared With Others';

const INITIAL_FILTERS = {
  template: null,
  level: null,
  duration: null,
};

const createQueryConfig = (filters, control, user) => {
  const baseSelect = '*, template (name, experience (name), duration (name))';
  const sharedSelect = 'shared_practice!inner(shared_user_id, owner_user_id, shared_email, created_at)';

  let select = baseSelect;
  const filterObject = {};

  if (filters.name) {
    filterObject.name = { ilike: `%${filters.name}%` };
  }

  if (filters.template) {
    filterObject.template_id = { eq: filters.template };
  }

  if (filters.duration || filters.level) {
    select = select.replace('template', 'template!inner');

    if (filters.level) {
      filterObject['template.experience_id'] = { eq: filters.level };
    }

    if (filters.duration) {
      filterObject['template.duration_id'] = { eq: filters.duration };
    }
  }

  switch (control) {
    case MY_PRACTICES:
      select += `, shared_practice (*)`;
      filterObject.user_id = { eq: user?.id };
      break;
    case SHARED_WITH_YOU:
      select += `, ${sharedSelect}`;
      filterObject['shared_practice.shared_user_id'] = { eq: user?.id };
      break;
    case SHARED_WITH_OTHERS:
      select += `, ${sharedSelect}`;
      filterObject['shared_practice.owner_user_id'] = { eq: user?.id };
      break;
    default:
      break;
  }

  return { select, filter: filterObject };
};

const iconStyles = { width: rem(18), height: rem(18) };

const GroupedLabel = Group.withProps({ justify: 'center', gap: 'xs', wrap: 'no-wrap', px: 'sm' });

const segmentedControlData = [
  {
    value: MY_PRACTICES,
    label: (
      <GroupedLabel>
        <IconUser style={iconStyles} />
        {MY_PRACTICES}
      </GroupedLabel>
    ),
  },
  {
    value: SHARED_WITH_YOU,
    label: (
      <GroupedLabel>
        <IconMail style={iconStyles} />
        {SHARED_WITH_YOU}
      </GroupedLabel>
    ),
  },
  {
    value: SHARED_WITH_OTHERS,
    label: (
      <GroupedLabel>
        <IconMailForward style={iconStyles} />
        {SHARED_WITH_OTHERS}
      </GroupedLabel>
    ),
  },
];

const LevelBadge = ({ level }) => (
  <Badge variant="outline" size="sm" color={EXPERIENCE_COLOR[level]}>
    {level}
  </Badge>
);

const NoResults = () => (
  <Paper withBorder shadow="md" p="md">
    <Stack h={200} justify="center" align="center">
      <Text align="center" style={{ textWrap: 'pretty' }}>
        Looks like you have not created any practices yet.
      </Text>
      <Button component={Link} to="/practices/create">
        Create Practice
      </Button>
    </Stack>
  </Paper>
);

const MobileFilters = ({ filtersToApply, handleFilterChange }) => {
  return (
    <>
      <TemplateSelect value={filtersToApply?.template} onChange={(value) => handleFilterChange('template', value)} size="md" />
      <LevelSelect
        value={filtersToApply?.level}
        onChange={(value) => handleFilterChange('level', value)}
        size="md"
        allowDeselect
        chips
      />
      <DurationSelect
        value={filtersToApply?.duration}
        onChange={(value) => handleFilterChange('duration', value)}
        size="md"
        chips
      />
    </>
  );
};
const FilterToolbar = ({ filters, setFilters, searchInputRef }) => {
  const isMobile = useMediaQuery('sm');

  const handleApply = (newFilters) => {
    setFilters(newFilters);
  };

  const handleClear = () => {
    setFilters(INITIAL_FILTERS);
  };

  const handleFiltersChange = (key, value) => {
    setFilters((current) => ({ ...current, [key]: value }));
  };

  if (isMobile) {
    return (
      <MobileFiltersDrawer filters={filters} initialFilters={INITIAL_FILTERS} onApply={handleApply} onClear={handleClear}>
        <MobileFilters />
      </MobileFiltersDrawer>
    );
  }

  return (
    <Grid
      type="container"
      breakpoints={{ xs: '576px', sm: '768px', md: '992px', lg: '1200px', xl: '1400px' }}
      gutter="xs"
      mb="lg"
    >
      <Grid.Col span={{ base: 12, sm: 6, md: 3 }}>
        <SearchInput
          ref={searchInputRef}
          label="Search"
          placeholder="Search by name"
          initialValue={filters.name}
          onSearch={(value) => handleFiltersChange('name', value)}
        />
      </Grid.Col>
      <Grid.Col span={{ base: 12, sm: 6, md: 3 }}>
        <TemplateSelect value={filters.template} onChange={(value) => handleFiltersChange('template', value)} size="md" />
      </Grid.Col>
      <Grid.Col span={{ base: 12, sm: 6, md: 3 }}>
        <LevelSelect value={filters.level} onChange={(value) => handleFiltersChange('level', value)} size="md" allowDeselect />
      </Grid.Col>
      <Grid.Col span={{ base: 12, sm: 6, md: 3 }}>
        <DurationSelect
          value={filters.duration}
          onChange={(value) => handleFiltersChange('duration', value)}
          size="md"
          defaultEnabled={false}
        />
      </Grid.Col>
    </Grid>
  );
};

export default function MyPractices() {
  const user = useUser();
  const [control, setControl] = useState(MY_PRACTICES);
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const searchInputRef = useRef();

  const { select, filter } = useMemo(() => createQueryConfig(filters, control, user), [filters, control, user]);

  const canShowNoResults = control === MY_PRACTICES && Object.keys(filters).length === 0;

  const columns = useMemo(
    () => [
      {
        accessor: 'name',
        render: ({ name, is_favorite, user_id }) => (
          <Group gap="xs" wrap="nowrap">
            {name}
            {is_favorite && user_id === user.id && <IconStarFilled size={16} color="orange" />}
          </Group>
        ),
      },
      {
        accessor: 'description',
        width: 400,
      },
      {
        accessor: 'template.experience.name',
        title: 'Level',
        width: 125,
        render: ({ template }) => (template?.experience?.name ? <LevelBadge level={template.experience.name} /> : '-'),
      },
      {
        accessor: 'template.duration.name',
        title: 'Duration',
        render: ({ template }) => template?.duration?.name || '-',
      },
      {
        accessor: 'template.name',
        title: 'Template',
        render: ({ template }) => template?.name || 'Custom',
      },
      {
        accessor: 'created_at',
        title: 'Created At',
      },
      {
        hidden: control !== SHARED_WITH_YOU,
        accessor: 'user_id',
        title: 'Owner',
        render: (practice) => <PracticeOwner practice={practice} />,
      },
    ],
    [control, user.id],
  );

  const handleControlChange = (value) => {
    setControl(value);
    setFilters(INITIAL_FILTERS);

    if (searchInputRef.current) {
      searchInputRef.current.reset();
    }
  };

  return (
    <Container size="xl" p={0}>
      <PageHeader
        title="Practices"
        actions={[
          <Button key="create-practice" component={Link} to="/practices/create" leftSection={<IconPlus size={18} stroke={3} />}>
            CREATE
          </Button>,
        ]}
      />
      <ScrollArea type="never">
        <SegmentedControl
          data={segmentedControlData}
          value={control}
          onChange={handleControlChange}
          color="snh"
          fullWidth
          size="md"
          mb="lg"
          bg="white"
          bd="1px solid gray.3"
        />
      </ScrollArea>
      <FilterToolbar filters={filters} setFilters={setFilters} searchInputRef={searchInputRef} />
      <DataTable select={select} filter={filter} columns={columns} enableRowClick noResults={canShowNoResults && NoResults} />
    </Container>
  );
}
