import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Group, Text } from '@mantine/core';

import { deleteRecordWithFile } from '../utils/fileUploadWithRecord';

import PageHeader from '../components/common/PageHeader';
import DataTable from '../components/common/DataTable';
import MobileFilters from '../components/drills/MobileFilters';
import DesktopFilters from '../components/drills/DesktopFilters';
import { DrillsProvider, useDrillsContext } from '../components/drills/DrillsProvider';
import { DeleteModalButton } from '../components/common/DeleteModal';
import { AddDrillDialogButton, EditDrillDialogButton } from '../components/drills/DrillDialog';

const createFilterObject = (filters) => {
  const filterObject = {};

  if (filters.name) filterObject.name = { ilike: `%${filters.name}%` };
  if (filters.category) filterObject.category_id = { eq: filters.category };
  if (filters.shooters) filterObject.shooters = { lte: filters.shooters };
  if (filters.level) filterObject.level = { lte: filters.level };

  return filterObject;
};

const DataTableWithFilters = ({ select, columns, handleRedirect }) => {
  const { filters } = useDrillsContext();
  const filter = useMemo(() => createFilterObject(filters), [filters]);

  return <DataTable select={select} columns={columns} filter={filter} enableRowClick handleRedirect={handleRedirect} />;
};

export default function AdminDrills() {
  const navigate = useNavigate();

  const select = '*, category(name, group)';

  const handleDelete = async (record, resource) => await deleteRecordWithFile(record, resource, 'video');

  const columns = useMemo(
    () => [
      {
        accessor: 'name',
      },
      {
        accessor: 'category.name',
        title: 'Category',
        render: ({ category }) => (
          <>
            <Text inherit>{category?.name}</Text>
            {category?.group && (
              <Text size="xs" c="dimmed">
                {category.group}
              </Text>
            )}
          </>
        ),
      },
      {
        accessor: 'level',
        textAlign: 'center',
      },
      {
        accessor: 'shooters',
        textAlign: 'center',
      },
      {
        accessor: 'created_at',
        title: 'Created At',
      },
      {
        accessor: 'actions',
        title: '',
        textAlign: 'right',
        render: (record) => (
          <Group justify="right" wrap="no-wrap">
            <EditDrillDialogButton initialValues={record} />
            <DeleteModalButton record={record} deleteFn={handleDelete} />
          </Group>
        ),
      },
    ],
    [],
  );

  const handleRedirect = (record, state) => {
    navigate(`/drills/${record.id}`, { state: { ...state, from: '/admin-drills' } });
  };

  return (
    <Container size="xl" p={0}>
      <PageHeader title="Drills" actions={[<AddDrillDialogButton key="create-drill-modal" />]} />
      <DrillsProvider>
        <DesktopFilters />
        <MobileFilters />
        <DataTableWithFilters select={select} columns={columns} handleRedirect={handleRedirect} />
      </DrillsProvider>
    </Container>
  );
}
