import { useQuery } from '@tanstack/react-query';
import { Badge, Card, Group, Text } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { IconActivity, IconClockHour3, IconStarFilled } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

import { EXPERIENCE_COLOR } from '../../constants';
import supabase from '../../config/supabaseClient';
import { useUser } from '../../context/SessionContext';
import { isLoggedIn } from '../auth/LoggedInRoutes';

import classes from './FavoritePractices.module.css';

const PracticeCard = ({ practice }) => {
  const navigate = useNavigate();

  const { name, description, template } = practice;
  const { duration, experience } = template || {};

  const onClick = () => navigate(`/practices/${practice.id}`, { state: { is_favorite: true } });

  return (
    <Card className={classes.cardHover} h="100%" shadow="sm" p="md" withBorder onClick={onClick}>
      <Group justify="space-between" gap="xs" mb={4}>
        <Text size="lg" fw={500}>
          {name}
        </Text>
        <IconStarFilled size={16} color="orange" />
      </Group>
      {practice?.template && (
        <Group gap="xs" mb="xs">
          <Badge size="sm" variant="outline" color={EXPERIENCE_COLOR[experience.name]} leftSection={<IconActivity size={14} />}>
            {experience.name}
          </Badge>
          <Badge size="sm" variant="outline" leftSection={<IconClockHour3 size={14} />}>
            {duration.name}
          </Badge>
        </Group>
      )}
      <Text size="sm" c="dimmed" lineClamp={2}>
        {description}
      </Text>
    </Card>
  );
};

const PracticeCarousel = ({ practices = [] }) => {
  return (
    <Carousel
      slideSize={{ base: '100%', xs: '50%', md: '33.333333%' }}
      slidesToScroll={{ base: 1, xs: 2, md: 3 }}
      slideGap={{ base: 'xs', sm: 'md' }}
      containScroll="trimSnaps"
      align="center"
      classNames={classes}
    >
      {practices?.map((practice) => {
        return (
          <Carousel.Slide key={practice.id}>
            <PracticeCard practice={practice} />
          </Carousel.Slide>
        );
      })}
    </Carousel>
  );
};

const FavoritePractices = () => {
  const user = useUser();
  const { data: practices } = useQuery({
    queryKey: ['practice', { is_favorite: true }],
    queryFn: async () =>
      await supabase
        .from('practice')
        .select('*, template (name, experience (name), duration (name)), shared_practice (*)')
        .eq('is_favorite', true)
        .throwOnError()
        .then(({ data }) => data),
    enabled: isLoggedIn(user),
  });

  if (!practices?.length || !isLoggedIn(user)) return null;

  return (
    <>
      <Text size="xl" fw={700} mb={4}>
        Favourite Practices
      </Text>
      <PracticeCarousel practices={practices} />
    </>
  );
};

export default FavoritePractices;
