import supabase, { getBucketPublicUrl } from '../config/supabaseClient';

const generateFilePath = (fileName) => {
  const now = new Date();
  const timestamp = now.toISOString().replace(/[-:]/g, '').replace('T', '_').slice(0, 15);

  return `${timestamp}_${fileName.replace(/\s+/g, '_')}`;
};

const generateVideoThumbnail = async (videoFile) => {
  const video = document.createElement('video');
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  video.src = URL.createObjectURL(videoFile);
  video.crossOrigin = 'anonymous';

  await new Promise((resolve, reject) => {
    video.onloadedmetadata = resolve;
    video.onerror = (error) => reject(new Error('Error loading video metadata: ' + error.message));
  });

  video.currentTime = 0.5;

  await new Promise((resolve, reject) => {
    video.onseeked = resolve;
    video.onerror = (error) => reject(new Error('Error seeking video: ' + error.message));
  });

  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  context.drawImage(video, 0, 0, canvas.width, canvas.height);

  const blob = await new Promise((resolve) => canvas.toBlob((blob) => resolve(blob), 'image/png', 0.8));

  URL.revokeObjectURL(video.src);
  return blob;
};

export const uploadFile = async (file, resource, isVideoFile = false) => {
  const filePath = generateFilePath(file.name);

  if (isVideoFile) {
    const thumbnail = await generateVideoThumbnail(file);
    const { error: thumbnailUploadError } = await supabase.storage.from('thumbnail').upload(filePath, thumbnail);
    if (thumbnailUploadError) throw thumbnailUploadError;
  }

  const { error: uploadError, data: fileData } = await supabase.storage.from(resource).upload(filePath, file);

  if (uploadError) throw uploadError;

  return getBucketPublicUrl(resource, fileData?.path);
};

export const removeFile = async (filePath, resource, isVideoFile = false) => {
  const fileName = filePath.split('/').pop();
  await supabase.storage.from(resource).remove([fileName]);

  if (isVideoFile) {
    await supabase.storage.from('thumbnail').remove([fileName]);
  }
};

export const createRecordWithFileUpload = async (data, resource, source) => {
  if (!resource || !source) throw new Error('Resource and source are required');

  try {
    if (data[source]) {
      const file = data[source][0];
      data[source] = await uploadFile(file, resource, source === 'video');
    }

    const { data: record, error: insertError } = await supabase.from(resource).insert(data).select().single();

    if (insertError) throw insertError;

    return record;
  } catch (error) {
    console.error('Create record with file failed:', error.message);
    return { error };
  }
};

export const editRecordWithFileUpload = async (data, initialValues, resource, source) => {
  if (!resource || !source) throw new Error('Resource and source are required');

  let newFilePath = null;

  try {
    if (data[source] && data[source] !== initialValues?.[source]) {
      const file = data[source][0];
      newFilePath = await uploadFile(file, resource, source === 'video');
      data[source] = newFilePath;

      if (initialValues?.[source]) {
        await removeFile(initialValues[source], resource, source === 'video');
      }
    } else if (!data[source] && initialValues?.[source]) {
      await removeFile(initialValues[source], resource, source === 'video');
      data[source] = null;
    }

    const { data: record, error: updateError } = await supabase
      .from(resource)
      .update(data)
      .eq('id', initialValues.id)
      .select()
      .single();

    if (updateError) throw updateError;

    return record;
  } catch (error) {
    console.error('Edit record with file failed:', error.message);

    if (newFilePath) {
      await removeFile(newFilePath, resource, source === 'video');
    }

    return { error };
  }
};

export const deleteRecordWithFile = async (record, resource, source) => {
  try {
    await supabase.from(resource).delete().eq('id', record.id).throwOnError();

    if (record[source]) {
      await removeFile(record[source], resource, source === 'video');
    }
  } catch (error) {
    console.error('Delete record with file failed:', error.message);
    return { error };
  }
};
